<template>
  <FormInputSelectWidget :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg" :options="mediaTypes" :value="value" @input="$emit('input', $event)" />
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget';

export default {
  name: 'select-module-type-widget',
  mixins: [formInputWidgetMixin],
  components: {
    FormInputSelectWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputSelectWidget'),
  },
  data () {
    return {
      mediaTypes: [
        { value: 1, label: 'Affiancamento orizzontale' },
        { value: 2, label: 'Affiancamento verticale' },
      ],
    };
  },
};

</script>
